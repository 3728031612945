.News {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10vh;
    color: black;
    list-style: none;
}

a {
    list-style: none;
    color: black;
}

.New {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10vh;
}

.NewHeader {
    margin: 5vmin;
    text-align: center;
    width: 90%;
}

.NewParagraph {
    margin: 2vmin 10vmin;
    text-align: left;
    width: 90%;
}

.NewsList {
    width: 80%;
}

.NewsListItem {
    background: var(--lavender);
    border: 2px solid var(--ultra-violet);
    padding: 0.5em 1em;
    width: 80%;
    margin: 1em;
    color: var(--ultra-violet);
}

.NewsTitle {
    color: var(--ultra-violet);
}

.NewsTitle:hover {
    color: var(--jordy-blue);
}