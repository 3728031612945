.ContactPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Contacts {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ContactLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 5vmin;
}

.ContactRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.ContactInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ContactIcon {
    margin: 10px;
}

.ContactImage {
    border-radius: 50%;
    width: 400px;
    height: 400px;
    background-size: cover;
    background-position: center;
}

