.SideMenuList {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;
    color: white;
}

.SideMenuItems {
    margin-top: 10vh;
}

.Navbar-item-text {
    text-align: left;
}

.Navbar-item .first {
    font-size: 1.2em;
}

.Navbar-item .second {
    font-size: 1em;
    margin-left: 2em;
}

.Navbar-item .third {
    font-size: 0.8em;
    margin-left: 4em;
}

.Navbar-side-menu {
    display: none;
}

@media (max-width: 1100px) {
    .Navbar-side-menu {
        display: block;
    }
}
