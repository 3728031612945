body {
	width: 100%;
	margin: 0;
	font-family: "Jost", serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--rose: #bf9487;
	--gray: #464F51;
	--yellow: #e5c398;
	--blue: #b9ced5;
	--green: #aebaa4;
	--ultra-violet: #576490;
	--vista-blue: #7796CB;
	--jordy-blue: #A3BCF9;
	--lavender: #D9E3FD;
	--dun: #CDC6AE;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
}

code {
	font-family: "Roboto", serif;
}

canvas {
    width: 100%;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}