.Album {
	height: 100%;
	width: 100%;
	margin-top: 10vh;
	min-height: 100vh;
}

.Album-header {
	text-align: center;
	font-size: 2em;
	margin: 1em;
	color: var(--ultra-violet);
	font-weight: bold;
}

.Album-list {
	min-height: 100vh;
	text-align: center;
}

.Album-item {
	width: 300px;
	color: white;
	display: inline-block;
	cursor: pointer;
	text-align: center;
	margin: 2vmin;
}

.Album-item-content {
	width: 300px;
	min-height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-size: cover;
}

.Album-item-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100px;
	margin: 0;
	font-weight: bold;
	font-size: 1em;
	color: white;
	background-color: var(--ultra-violet);
}

.Album-item-content:hover {
	box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.PhotoAlbumPage {
	min-height: 100vh;
	color: var(--dark-blue);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 10vh;
}